import axios, { AxiosRequestConfig, AxiosHeaders, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import {
  SystemTypeReqEntity,
  PaginateReqEntity,
  SubSystemReqEntity,
  SystemReqEntity,
  UsersDetailsReqEntity,
  GetAllUsersReqEntity,
  ModelsReqEntity,
  GetAllSystemReqEntity,
  GetAllModelsReqEntity,
  GlassSubCategoryReqEntity,
  GetAllWaltzSubCategoriesReqEntity,
  GlassCategoryReqEntity,
  GLGlassVariantsReqEntity,
  GLGlassFinishesReqEntity,
  FetchOrientationSubOrientationReq,
  FetchOrderConfigurationReq,
  FrameColorsReqEntity,
  DesginReqEntity,
  GetAllDesignReqEntity,
  GlassVariantsReqEntity,
  GetAllWaltzVariantReqEntity,
  GlassFinishesReqEntity,
  GetAllWaltzFinishReqEntity,
  HandlesReqEntity,
  LockReqEntity,
  SignInReqEntity,
  GlGlassCategoryReqEntity,
  GlGlassSubCategoryReqEntity,
  GlassOnlyFinishesEntity,
  DownloadReqBodyEntity,
  RolesReqEntity,
  CreateUpdateSolutionReqPayloadEntity,
  ChangePasswordReqEntity,
  FirstTimeChangePasswordReqEntity,
  StatesReqEntity,
  GetAllStatesResEntity,
  SaveUserMappingReqBodyEntity,
  CreateReportFieldsMappingReqEntity,
  GlassMappingEntity,
  GoodsDeleveryEntity,
  CreateUpdateGlassSolutionReqPayloadEntity,
} from "../interface";
import {
  GetGlassOrderConfigReq,
  GlassOrderSolutionReq,
  CreateGlassOrderReqEntity,
  GenerateGlassOrderReqEntity,
  UpdateGlassOrderStatusHandoverReq,
  ConfigReqBodyEntity,
} from "../interface/glass-order";
import {
  CustomerBillingInformationReqBodyEntity,
  LedgerReqEntity,
} from "../interface/ledger-form";
import {
  CreateOrderReqEntity,
  UpdateOrderStatusCommercialWinReqEntity,
  UpdateOrderStatusHandoverReq,
  GetAllOrdersReqEntityV1,
  GenerateOrderReqEntity,
  OrderPaginateEntity,
  UpdateOrderReqEntity,
  UpdateOrderStatusPreclosureReq,
  PreClosureOrderStatusCommercialWinReqEntity,
} from "../interface/order";
import { ReleaseNewPricingVersionReqEntity } from "../interface/pricing-version";
import { BaseEndpoints } from "../utils/base-endpoints";
import { toastOptObj } from "../utils/constants";
import { StorageKeyEnum } from "../utils/enum";
import {
  IsNullOrEmptyArray,
  IsNullOrUndefined,
  IsStringNullEmptyOrUndefined,
} from "../utils/null-check";
import { getLocalStorageItem } from "./storage-service";
import { CityReqEntity, GetAllCitiesResEntity } from "../interface/cities";
import { CreateUpdateOfficeReqEntity } from "../interface/offices";
import {
  CreateRefundPaymentReqPayloadEntity,
  GeneratePaymentLinkReqEntity,
  GetAllPaymentRefundHistoryReqEntity,
  GetAllSettlementsReqEntity,
  SaveAsDraftTransactionReqEntity,
  SaveVoucherNumberReqEntity,
} from "../interface/payment";
import { GetAllPaymentHistoryReqEntity } from "../interface/payment-history";
import { ApproveArchitectDetailsReqBodyEntity, GetAllArchitectDetailsReqEntity } from "../interface/architect-details";
import { DashboardFiltersEntity } from "../interface/dashboard";
import { PanelOnlyReqEntity } from "../interface/panel-only";

export const APIURL = process.env.REACT_APP_API_URL;
// export const APIURL = "https://jb-glass-uat-apis.jarvistechnolabs.com/web";

const axiosConfig: AxiosRequestConfig = {
  headers: {
    "Content-Type": "application/json",
  },
};

axios.interceptors.request.use(
  (config) => {
    if (axiosConfig.headers) {
      axiosConfig.headers["authorization"] = `Bearer ${getLocalStorageItem(
        StorageKeyEnum.JB_GLASS_AUTH_TOKEN
      )}`;
    }
    config.headers = axiosConfig.headers as AxiosHeaders;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let message =
      "A small error has occurred, causing an interruption of service. Please try again";

    console.log("Error\n", error);

    if (axios.isAxiosError(error) && error.code === "ERR_NETWORK") {
      message =
        "We're having trouble connecting to the network. Please try again later.";
    }

    if (error && error.response) {
      const errResp: AxiosResponse = error.response;

      if (errResp && errResp.data && !errResp.data.status) {
        message =
          errResp.data &&
            errResp.data.statusCode &&
            errResp.data.statusCode === 400
            ? errResp.data.message
            : error.response.data.message;

        setTimeout(() => {
          if (error.response.data && error.response.data.statusCode === 440) {
            window.location.href = "/";
            localStorage.removeItem(StorageKeyEnum.JB_GLASS_AUTH_TOKEN);
          }

          if (error.response.data && error.response.data.statusCode === 503) {
            window.location.href = "/under-maintenance";
            localStorage.removeItem(StorageKeyEnum.JB_GLASS_AUTH_TOKEN);
          }
        }, 3000);
      }
    }

    toast.error(message, { ...toastOptObj });
    // Promise.reject(error);
  }
);

export const APIFetchOrdersForPyamentAutoSuggestion =
  async (): Promise<AxiosResponse> => {
    return await axios.get(
      `${APIURL}/${BaseEndpoints.order}/v1.0/fetch-orders-for-payment-auto-suggestion-request`
    );
  };

export const APIGeneratePaymentLink = async (
  body: GeneratePaymentLinkReqEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.payment}/generate-payment-link`,
    body
  );
};

export const APISaveTransactionAsDraft = async (
  body: SaveAsDraftTransactionReqEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.payment}/save-as-draft`,
    body
  );
};

export const APIGetAllPaymentHistory = async (body: GetAllPaymentHistoryReqEntity): Promise<AxiosResponse> => {
  let queryParams: string = `pageSize=${body.pageSize}&pageNumber=${body.pageNumber + 1}&sortColumn=${body.sortColumn}&sortOrder=${body.sortOrder}`;

  if (body.startDate && !IsStringNullEmptyOrUndefined(body.startDate)) {
    queryParams = queryParams.concat(`&startDate=${body.startDate}`);
  }

  if (body.endDate && !IsStringNullEmptyOrUndefined(body.endDate)) {
    queryParams = queryParams.concat(`&endDate=${body.endDate}`);
  }

  if (body.settlementStartDate && !IsStringNullEmptyOrUndefined(body.settlementStartDate)) {
    queryParams = queryParams.concat(`&settlementStartDate=${body.settlementStartDate}`);
  }

  if (body.settlementEndDate && !IsStringNullEmptyOrUndefined(body.settlementEndDate)) {
    queryParams = queryParams.concat(`&settlementEndDate=${body.settlementEndDate}`);
  }

  if (body.searchTransactionReferenceId && !IsStringNullEmptyOrUndefined(body.searchTransactionReferenceId)) {
    queryParams = queryParams.concat(`&searchTransactionReferenceId=${body.searchTransactionReferenceId}`);
  }

  if (body.searchOfficeName && !IsStringNullEmptyOrUndefined(body.searchOfficeName)) {
    queryParams = queryParams.concat(`&searchOfficeName=${body.searchOfficeName}`);
  }

  if (body.searchPaymentMode && !IsStringNullEmptyOrUndefined(body.searchPaymentMode)) {
    queryParams = queryParams.concat(`&searchPaymentMode=${body.searchPaymentMode}`);
  }

  if (body.status && !IsStringNullEmptyOrUndefined(body.status)) {
    queryParams = queryParams.concat(`&status=${body.status}`);
  }

  return await axios.get(`${APIURL}/${BaseEndpoints.paymentHistory}/get-all?${queryParams}`);
};

export const APIFetchTransactionSummary = async (
  transactionReferenceId: string
): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.payment}/get-transaction-summary/${transactionReferenceId}`
  );
};

export const APICreateRefundPayment = async (reqBody: CreateRefundPaymentReqPayloadEntity): Promise<AxiosResponse> => {
  return await axios.post(`${APIURL}/${BaseEndpoints.refundPayment}/create`, reqBody);
};

export const APIGetAllRefundPaymentHistory = async (body: GetAllPaymentRefundHistoryReqEntity): Promise<AxiosResponse> => {
  let queryParams: string = `pageSize=${body.pageSize}&pageNumber=${body.pageNumber + 1}`;

  if (body.searchTransactionReferenceId && !IsStringNullEmptyOrUndefined(body.searchTransactionReferenceId)) {
    queryParams = queryParams.concat(`&searchTransactionReferenceId=${body.searchTransactionReferenceId}`);
  }

  if (body.status && !IsStringNullEmptyOrUndefined(body.status)) {
    queryParams = queryParams.concat(`&status=${body.status}`);
  }

  return await axios.get(`${APIURL}/${BaseEndpoints.refundPayment}/get-all-history?${queryParams}`);
};

export const APICheckAndAdjustInitiatedTransactions = async (
  transactionReferenceId: string
): Promise<AxiosResponse> => {
  return await axios.put(
    `${APIURL}/${BaseEndpoints.payment}/check-and-adjust-initiated-transactions/${transactionReferenceId}`
  );
};

export const APIExportPaymentHistoryTransaction = async (): Promise<AxiosResponse> => {
  return await axios.get(`${APIURL}/${BaseEndpoints.paymentHistory}/export-transaction`);
};

export const APIExportRefundPaymentHistory = async (): Promise<AxiosResponse> => {
  return await axios.get(`${APIURL}/${BaseEndpoints.refundPayment}/export-refund-history`);
};

export const APIExportSettlements = async (): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.payment}/export-settlements`
  );
};

export const APIExportOrderDues = async (): Promise<AxiosResponse> => {
  return await axios.get(`${APIURL}/${BaseEndpoints.order}-dues/export-dues`);
};

export const APISaveVoucherNumber = async (
  reqBody: SaveVoucherNumberReqEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.payment}/save-voucher-number`,
    reqBody
  );
};

export const APIGetAllSettlements = async (
  body: GetAllSettlementsReqEntity
): Promise<AxiosResponse> => {
  let queryParams: string = `pageSize=${body.pageSize}&pageNumber=${body.pageNumber + 1
    }`;

  if (
    body.settlementStartDate &&
    !IsStringNullEmptyOrUndefined(body.settlementStartDate)
  ) {
    queryParams = queryParams.concat(
      `&settlementStartDate=${body.settlementStartDate}`
    );
  }

  if (
    body.settlementEndDate &&
    !IsStringNullEmptyOrUndefined(body.settlementEndDate)
  ) {
    queryParams = queryParams.concat(
      `&settlementEndDate=${body.settlementEndDate}`
    );
  }

  return await axios.get(
    `${APIURL}/${BaseEndpoints.payment}/get-all-settlements?${queryParams}`
  );
};

export const APIGetAllDraftTransactions = async (body: {
  pageSize: number;
  pageNumber: number;
}): Promise<AxiosResponse> => {
  const queryParams: string = `pageSize=${body.pageSize}&pageNumber=${body.pageNumber + 1
    }`;
  return await axios.get(
    `${APIURL}/${BaseEndpoints.payment}/get-all-draft?${queryParams}`
  );
};

export const APIDeleteDraftTransaction = async (
  draftNo: string
): Promise<AxiosResponse> => {
  return await axios.delete(
    `${APIURL}/${BaseEndpoints.payment}/delete-draft-transaction/${draftNo}`
  );
};

export const APIFetchOrderDues = async (
  searchOrderNumber: string | null
): Promise<AxiosResponse> => {
  let query = "";

  if (
    searchOrderNumber &&
    !IsNullOrUndefined(searchOrderNumber) &&
    !IsStringNullEmptyOrUndefined(searchOrderNumber)
  ) {
    query = query.concat(`searchOrderNumber=${searchOrderNumber}`);
  }

  return await axios.get(
    `${APIURL}/${BaseEndpoints.order}-dues/get-all?${query}`
  );
};

export const APIAnalyticsDDList = async (
  reqObj: any
): Promise<AxiosResponse> => {
  return await axios.post(`${APIURL}/report/get-orders-report`, reqObj);
};

export const APICreateSystemType = async (
  reqObj: SystemTypeReqEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.systemTypeSetting}/create`,
    reqObj
  );
};

export const APIprocoreArchitect = async (): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.analytics}/refresh-procore-architect`
  );
};

export const APIFetchDetailsSystemType = async (
  filterReq: PaginateReqEntity
): Promise<AxiosResponse> => {
  let query = "";

  query = query.concat(`pageSize=${filterReq.pageSize}`);

  query = query.concat(`&pageNumber=${filterReq.pageNumber + 1}`);

  query = query.concat(`&searchText=${filterReq.searchText}`);

  return await axios.get(
    `${APIURL}/${BaseEndpoints.systemTypeSetting}/get-all?${query}`
  );
};

export const APIUpdateSystemType = async (
  reqObj: SystemTypeReqEntity,
  id: string
): Promise<AxiosResponse> => {
  return await axios.put(
    `${APIURL}/${BaseEndpoints.systemTypeSetting}/update/${id}`,
    reqObj
  );
};

export const APIDeleteSystemType = async (
  id: string
): Promise<AxiosResponse> => {
  return await axios.delete(
    `${APIURL}/${BaseEndpoints.systemTypeSetting}/delete/${id}`
  );
};

export const APICreateSubSystem = async (
  reqObj: SubSystemReqEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.subSystemSetting}/create`,
    reqObj
  );
};

export const APIFetchDetailsSubSystem = async (
  filterReq: PaginateReqEntity
): Promise<AxiosResponse> => {
  let query = "";

  query = query.concat(`pageSize=${filterReq.pageSize}`);

  query = query.concat(`&pageNumber=${filterReq.pageNumber + 1}`);

  query = query.concat(`&searchText=${filterReq.searchText}`);

  return await axios.get(
    `${APIURL}/${BaseEndpoints.subSystemSetting}/get-all?${query}`
  );
};

export const APIUpdateSubSystem = async (
  reqObj: SubSystemReqEntity,
  id: string
): Promise<AxiosResponse> => {
  return await axios.put(
    `${APIURL}/${BaseEndpoints.subSystemSetting}/update/${id}`,
    reqObj
  );
};

export const APIDeleteSubSystem = async (
  id: string
): Promise<AxiosResponse> => {
  return await axios.delete(
    `${APIURL}/${BaseEndpoints.subSystemSetting}/delete/${id}`
  );
};

export const APICreateSystem = async (
  reqObj: SystemReqEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.systemSetting}/create`,
    reqObj
  );
};

export const APIGetSystemTypeDDL = async (
  ids: string[] | [] = []
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.systemTypeSetting}/get-ddl`,
    { ids }
  );
};

export const APIGetSubSystemTypeDDL = async (
  subSystemIds: string[] | [] = []
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.subSystemSetting}/get-ddl`,
    { ids: subSystemIds }
  );
};

export const APIGetSystemDDL = async (
  systemId: string | undefined = "",
  subSystemId: string | undefined = "",
  systemTypeId: string | undefined = "",
  forFilter: boolean
): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.systemSetting}/get-ddl?systemId=${systemId}&subSystemId=${subSystemId}&systemTypeId=${systemTypeId}&forFilter=${forFilter}`
  );
};

export const APIFetchDetailsSystem = async (
  filterReq: PaginateReqEntity
): Promise<AxiosResponse> => {
  let query = "";

  query = query.concat(`pageSize=${filterReq.pageSize}`);

  query = query.concat(`&pageNumber=${filterReq.pageNumber + 1}`);

  query = query.concat(`&searchText=${filterReq.searchText}`);

  return await axios.get(
    `${APIURL}/${BaseEndpoints.systemSetting}/get-all?${query}`
  );
};

export const APIDeleteSystem = async (id: string): Promise<AxiosResponse> => {
  return await axios.delete(
    `${APIURL}/${BaseEndpoints.systemSetting}/delete/${id}`
  );
};

export const APIUpdateSystem = async (
  reqObj: SystemReqEntity,
  id: string
): Promise<AxiosResponse> => {
  return await axios.put(
    `${APIURL}/${BaseEndpoints.systemSetting}/update/${id}`,
    reqObj
  );
};

export const APIGetSystemById = async (id: string): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.systemSetting}/get-system/${id}`
  );
};

export const APIGetWaltzFinishDDL = async (data: {
  ids: string[] | [];
}): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.glassFinishSettings}/get-ddl`,
    data
  );
};

export const APIGetUserRolesDDL = async (): Promise<AxiosResponse> => {
  return await axios.get(`${APIURL}/${BaseEndpoints.role}/get-ddl`);
};

export const APICreateUser = async (
  reqObj: UsersDetailsReqEntity
): Promise<AxiosResponse> => {
  return await axios.post(`${APIURL}/${BaseEndpoints.user}/create`, reqObj);
};

export const APIUpdateUser = async (
  reqObj: UsersDetailsReqEntity,
  id: string
): Promise<AxiosResponse> => {
  return await axios.put(
    `${APIURL}/${BaseEndpoints.user}/update/${id}`,
    reqObj
  );
};

export const APIFetchUsers = async (
  filterReq: GetAllUsersReqEntity
): Promise<AxiosResponse> => {
  let query = "";

  query = query.concat(`pageSize=${filterReq.pageSize}`);

  query = query.concat(`&pageNumber=${filterReq.pageNumber + 1}`);

  if (
    filterReq.sortColumn &&
    !IsStringNullEmptyOrUndefined(filterReq.sortColumn)
  ) {
    query = query.concat(`&sortColumn=${filterReq.sortColumn}`);
  }

  if (
    filterReq.sortOrder &&
    !IsStringNullEmptyOrUndefined(filterReq.sortOrder)
  ) {
    query = query.concat(`&sortOrder=${filterReq.sortOrder}`);
  }

  if (filterReq.userName && !IsStringNullEmptyOrUndefined(filterReq.userName)) {
    query = query.concat(`&userName=${filterReq.userName}`);
  }

  if (filterReq.mobileNo && !IsStringNullEmptyOrUndefined(filterReq.mobileNo)) {
    query = query.concat(`&mobileNo=${filterReq.mobileNo}`);
  }

  if (
    filterReq.emailAddress &&
    !IsStringNullEmptyOrUndefined(filterReq.emailAddress)
  ) {
    query = query.concat(`&emailAddress=${filterReq.emailAddress}`);
  }

  if (
    filterReq.userFullName &&
    !IsStringNullEmptyOrUndefined(filterReq.userFullName)
  ) {
    query = query.concat(`&userFullName=${filterReq.userFullName}`);
  }

  if (
    filterReq.officeName &&
    !IsStringNullEmptyOrUndefined(filterReq.officeName)
  ) {
    query = query.concat(`&officeName=${filterReq.officeName}`);
  }

  if (
    filterReq.createFromDate &&
    !IsStringNullEmptyOrUndefined(filterReq.createFromDate)
  ) {
    query = query.concat(`&createFromDate=${filterReq.createFromDate}`);
  }

  if (
    filterReq.createToDate &&
    !IsStringNullEmptyOrUndefined(filterReq.createToDate)
  ) {
    query = query.concat(`&createToDate=${filterReq.createToDate}`);
  }

  if (filterReq.roleId && !IsStringNullEmptyOrUndefined(filterReq.roleId)) {
    query = query.concat(`&roleId=${filterReq.roleId}`);
  }

  if (filterReq.status) {
    query = query.concat(`&status=${filterReq.status}`);
  }

  if (filterReq.teamCategory) {
    query = query.concat(`&teamCategory=${filterReq.teamCategory}`);
  }
  return await axios.get(`${APIURL}/${BaseEndpoints.user}/get-all?${query}`);
};

export const APIDeleteUser = async (id: string): Promise<AxiosResponse> => {
  return await axios.delete(`${APIURL}/${BaseEndpoints.user}/delete/${id}`);
};

export const APIFetchUsersByRoleName = async (
  roleName: string
): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.user}/fetch-users-by-role?role=${roleName}`
  );
};

export const APIFetchUserById = async (id: string): Promise<AxiosResponse> => {
  return await axios.get(`${APIURL}/${BaseEndpoints.user}/get-user/${id}`);
};

export const APICreateModel = async (
  reqObj: ModelsReqEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.modelSetting}/create`,
    reqObj
  );
};

export const APIUpdateModel = async (
  reqObj: ModelsReqEntity,
  id: string
): Promise<AxiosResponse> => {
  return await axios.put(
    `${APIURL}/${BaseEndpoints.modelSetting}/update/${id}`,
    reqObj
  );
};

export const APIUplodeMediaModels = async (
  data: FormData,
  id: string,
  fieldName: string
): Promise<AxiosResponse> => {
  const instance = axios.create({
    headers: {
      "content-type": "multipart/form-data",
      "Custom-Multipart-Header": "true",
      Authorization: `Bearer ${getLocalStorageItem(
        StorageKeyEnum.JB_GLASS_AUTH_TOKEN
      )}`,
      fieldName: fieldName,
    },
  });
  return await instance.put(
    `${APIURL}/${BaseEndpoints.modelSetting}/upload-media/${id}`,
    data
  );
};

export const APIFetchDetailsAllNewSystem = async (
  filterReq: GetAllSystemReqEntity
): Promise<AxiosResponse> => {
  let query = "";

  query = query.concat(`pageSize=${filterReq.pageSize}`);

  query = query.concat(`&pageNumber=${filterReq.pageNumber + 1}`);

  if (filterReq.fromDate && !IsStringNullEmptyOrUndefined(filterReq.fromDate)) {
    query = query.concat(`&fromDate=${filterReq.fromDate}`);
  }

  if (filterReq.toDate && !IsStringNullEmptyOrUndefined(filterReq.toDate)) {
    query = query.concat(`&toDate=${filterReq.toDate}`);
  }

  if (
    filterReq.sortColumn &&
    !IsStringNullEmptyOrUndefined(filterReq.sortColumn)
  ) {
    query = query.concat(`&sortColumn=${filterReq.sortColumn}`);
  }

  if (
    filterReq.sortOrder &&
    !IsStringNullEmptyOrUndefined(filterReq.sortOrder)
  ) {
    query = query.concat(`&sortOrder=${filterReq.sortOrder}`);
  }

  if (filterReq.systemId && !IsStringNullEmptyOrUndefined(filterReq.systemId)) {
    query = query.concat(`&systemId=${filterReq.systemId}`);
  }

  if (
    filterReq.subSystemId &&
    !IsStringNullEmptyOrUndefined(filterReq.subSystemId)
  ) {
    query = query.concat(`&subSystemId=${filterReq.subSystemId}`);
  }

  if (
    filterReq.systemTypeId &&
    !IsStringNullEmptyOrUndefined(filterReq.systemTypeId)
  ) {
    query = query.concat(`&systemTypeId=${filterReq.systemTypeId}`);
  }

  if (filterReq.status) {
    query = query.concat(`&status=${filterReq.status}`);
  }

  return await axios.get(
    `${APIURL}/${BaseEndpoints.systemSetting}/get-all-new?${query}`
  );
};

export const APIFetchDetailsModel = async (
  filterReq: GetAllModelsReqEntity
): Promise<AxiosResponse> => {
  let query = "";

  query = query.concat(`pageSize=${filterReq.pageSize}`);

  query = query.concat(`&pageNumber=${filterReq.pageNumber + 1}`);

  if (
    filterReq.orientation &&
    !IsStringNullEmptyOrUndefined(filterReq.orientation)
  ) {
    query = query.concat(`&orientation=${filterReq.orientation}`);
  }

  if (
    filterReq.subOrientation &&
    !IsStringNullEmptyOrUndefined(filterReq.subOrientation)
  ) {
    query = query.concat(`&subOrientation=${filterReq.subOrientation}`);
  }

  if (
    filterReq.createFromDate &&
    !IsStringNullEmptyOrUndefined(filterReq.createFromDate)
  ) {
    query = query.concat(`&createFromDate=${filterReq.createFromDate}`);
  }

  if (
    filterReq.createToDate &&
    !IsStringNullEmptyOrUndefined(filterReq.createToDate)
  ) {
    query = query.concat(`&createToDate=${filterReq.createToDate}`);
  }

  if (
    filterReq.sortColumn &&
    !IsStringNullEmptyOrUndefined(filterReq.sortColumn)
  ) {
    query = query.concat(`&sortColumn=${filterReq.sortColumn}`);
  }

  if (
    filterReq.sortOrder &&
    !IsStringNullEmptyOrUndefined(filterReq.sortOrder)
  ) {
    query = query.concat(`&sortOrder=${filterReq.sortOrder}`);
  }

  if (filterReq.systemId && !IsStringNullEmptyOrUndefined(filterReq.systemId)) {
    query = query.concat(`&systemId=${filterReq.systemId}`);
  }

  if (
    filterReq.subSystemId &&
    !IsStringNullEmptyOrUndefined(filterReq.subSystemId)
  ) {
    query = query.concat(`&subSystemId=${filterReq.subSystemId}`);
  }

  if (
    filterReq.systemTypeId &&
    !IsStringNullEmptyOrUndefined(filterReq.systemTypeId)
  ) {
    query = query.concat(`&systemTypeId=${filterReq.systemTypeId}`);
  }

  if (filterReq.status) {
    query = query.concat(`&status=${filterReq.status}`);
  }

  return await axios.get(
    `${APIURL}/${BaseEndpoints.modelSetting}/get-all?${query}`
  );
};

export const APIGetModelById = async (id: string): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.modelSetting}/get-model/${id}`
  );
};

export const APIDeleteModel = async (id: string): Promise<AxiosResponse> => {
  return await axios.delete(
    `${APIURL}/${BaseEndpoints.modelSetting}/delete/${id}`
  );
};

export const APIGlassSubCategoriesDDL = async (data: {
  ids: string[] | [];
}): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.glassSubCategoriesSettings}/get-ddl`,
    data
  );
};

export const APICreateGlassSubCategory = async (
  reqPayload: GlassSubCategoryReqEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.glassSubCategoriesSettings}/create`,
    reqPayload
  );
};

export const APIUpdateGlassSubCategory = async (
  id: string,
  reqPayload: GlassSubCategoryReqEntity
): Promise<AxiosResponse> => {
  return await axios.put(
    `${APIURL}/${BaseEndpoints.glassSubCategoriesSettings}/update/${id}`,
    reqPayload
  );
};

export const APIDeleteGlassSubCategory = async (
  id: string
): Promise<AxiosResponse> => {
  return await axios.delete(
    `${APIURL}/${BaseEndpoints.glassSubCategoriesSettings}/delete/${id}`
  );
};

export const APIFetchSubCategorty = async (
  filterReq: GetAllWaltzSubCategoriesReqEntity
): Promise<AxiosResponse> => {
  let query = "";

  query = query.concat(`pageSize=${filterReq.pageSize}`);

  query = query.concat(`&pageNumber=${filterReq.pageNumber + 1}`);

  if (
    filterReq.sortColumn &&
    !IsStringNullEmptyOrUndefined(filterReq.sortColumn)
  ) {
    query = query.concat(`&sortColumn=${filterReq.sortColumn}`);
  }

  if (
    filterReq.sortOrder &&
    !IsStringNullEmptyOrUndefined(filterReq.sortOrder)
  ) {
    query = query.concat(`&sortOrder=${filterReq.sortOrder}`);
  }

  if (filterReq.fromDate && !IsStringNullEmptyOrUndefined(filterReq.fromDate)) {
    query = query.concat(`&fromDate=${filterReq.fromDate}`);
  }

  if (filterReq.toDate && !IsStringNullEmptyOrUndefined(filterReq.toDate)) {
    query = query.concat(`&toDate=${filterReq.toDate}`);
  }

  if (
    filterReq.searchFinishName &&
    !IsStringNullEmptyOrUndefined(filterReq.searchFinishName)
  ) {
    query = query.concat(`&searchFinishName=${filterReq.searchFinishName}`);
  }

  if (
    filterReq.searchSubCategoryName &&
    !IsStringNullEmptyOrUndefined(filterReq.searchSubCategoryName)
  ) {
    query = query.concat(
      `&searchSubCategoryName=${filterReq.searchSubCategoryName}`
    );
  }

  if (filterReq.status) {
    query = query.concat(`&status=${filterReq.status}`);
  }

  return await axios.get(
    `${APIURL}/${BaseEndpoints.glassSubCategoriesSettings}/get-all?${query}`
  );
};

export const APICreateGlassCategory = async (
  reqPayload: GlassCategoryReqEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.glassCategoriesSettings}/create`,
    reqPayload
  );
};

export const APIUpdateGlassCategory = async (
  id: string,
  reqPayload: GlassCategoryReqEntity
): Promise<AxiosResponse> => {
  return await axios.put(
    `${APIURL}/${BaseEndpoints.glassCategoriesSettings}/update/${id}`,
    reqPayload
  );
};

export const APIDeleteGlassCategory = async (
  id: string
): Promise<AxiosResponse> => {
  return await axios.delete(
    `${APIURL}/${BaseEndpoints.glassCategoriesSettings}/delete/${id}`
  );
};

export const APIFetchCategorty = async (
  filterReq: PaginateReqEntity
): Promise<AxiosResponse> => {
  let query = "";

  query = query.concat(`pageSize=${filterReq.pageSize}`);

  query = query.concat(`&pageNumber=${filterReq.pageNumber + 1}`);

  query = query.concat(`&searchText=${filterReq.searchText}`);

  return await axios.get(
    `${APIURL}/${BaseEndpoints.glassCategoriesSettings}/get-all?${query}`
  );
};

export const APICreateGLGlassVariants = async (
  reqPayload: GLGlassVariantsReqEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.glGlassVariantSettings}/create`,
    reqPayload
  );
};

export const APIUpdateGLGlassVariants = async (
  id: string,
  reqPayload: GLGlassVariantsReqEntity
): Promise<AxiosResponse> => {
  return await axios.put(
    `${APIURL}/${BaseEndpoints.glGlassVariantSettings}/update/${id}`,
    reqPayload
  );
};

export const APIFetchGLGlassVariants = async (
  filterReq: PaginateReqEntity
): Promise<AxiosResponse> => {
  let query = "";

  query = query.concat(`pageSize=${filterReq.pageSize}`);

  query = query.concat(`&pageNumber=${filterReq.pageNumber + 1}`);

  query = query.concat(`&searchText=${filterReq.searchText}`);

  return await axios.get(
    `${APIURL}/${BaseEndpoints.glGlassVariantSettings}/get-all?${query}`
  );
};

export const APIDeleteGLGlassVariant = async (
  id: string
): Promise<AxiosResponse> => {
  return await axios.delete(
    `${APIURL}/${BaseEndpoints.glGlassVariantSettings}/delete/${id}`
  );
};

export const APIGLGlassVariantsDDL = async (): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.glGlassVariantSettings}/get-ddl`
  );
};

export const APICreateGLGlassFinishes = async (
  reqPayload: GLGlassFinishesReqEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.glGlassfinishesSettings}/create`,
    reqPayload
  );
};

export const APIUpdateGLGlassFinishes = async (
  id: string,
  reqPayload: GLGlassFinishesReqEntity
): Promise<AxiosResponse> => {
  return await axios.put(
    `${APIURL}/${BaseEndpoints.glGlassfinishesSettings}/update/${id}`,
    reqPayload
  );
};

export const APIFetchGLGlassFinishes = async (
  filterReq: PaginateReqEntity
): Promise<AxiosResponse> => {
  let query = "";

  query = query.concat(`pageSize=${filterReq.pageSize}`);

  query = query.concat(`&pageNumber=${filterReq.pageNumber + 1}`);

  query = query.concat(`&searchText=${filterReq.searchText}`);

  return await axios.get(
    `${APIURL}/${BaseEndpoints.glGlassfinishesSettings}/get-all?${query}`
  );
};

export const APIDeleteGLGlassFinishes = async (
  id: string
): Promise<AxiosResponse> => {
  return await axios.delete(
    `${APIURL}/${BaseEndpoints.glGlassfinishesSettings}/delete/${id}`
  );
};

export const APIHandlesSettingsDDL = async (
  handleIds: [] | string[]
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.handlesSettings}/get-ddl`,
    { ids: handleIds }
  );
};

export const APIFrameColorsDDL = async (
  ids: string[] | [] = []
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.frameColorsSettings}/get-ddl`,
    { ids }
  );
};

export const APIGridDDL = async (data: {
  ids: string[] | [];
}): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.gridSettings}/get-ddl`,
    data
  );
};

export const APILockDDL = async (
  ids: string[] | [] = []
): Promise<AxiosResponse> => {
  return await axios.post(`${APIURL}/${BaseEndpoints.lockSettings}/get-ddl`, {
    ids,
  });
};

export const APIGlassCategoriesDDL = async (
  glassMapping: GlassMappingEntity[] | undefined | []
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.glassCategoriesSettings}/get-ddl`,
    { mapping: glassMapping }
  );
};

export const APIGetSourceDDL = async (): Promise<AxiosResponse> => {
  return await axios.get(`${APIURL}/${BaseEndpoints.source}/get-ddl`);
};

export const APICreateOrder = async (
  reqObj: CreateOrderReqEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.order}/create-order`,
    reqObj
  );
};

export const APIUpdateOrderStatusCommercialWin = async (
  reqObj: UpdateOrderStatusCommercialWinReqEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.order}/v1.0/update-order-status-commercial-win`,
    reqObj
  );
};

export const APIUpdateOrderStatusHandover = async (
  reqObj: UpdateOrderStatusHandoverReq
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.order}/update-order-status-handover`,
    reqObj
  );
};

export const APIGetOrderDetailsById = async (
  orderId: string,
  skipCS: boolean
): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.order}/fetch-order-details/${orderId}?skipCS=${skipCS}`
  );
};

export const APIGetOrientationsDetails = async (
  reqObj: FetchOrientationSubOrientationReq
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.modelSetting}/get-orientations-details`,
    reqObj
  );
};

export const APIGetOrderConfigurationDetails = async (
  reqObj: FetchOrderConfigurationReq
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.modelSetting}/get-order-configuration`,
    reqObj
  );
};

export const APICreateSolution = async (
  reqObj: any
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.order}/create-solution`,
    reqObj
  );
};

export const APIEditSolution = async (
  orderId: string,
  solutionId: string,
  reqObj: any
): Promise<AxiosResponse> => {
  return await axios.put(
    `${APIURL}/${BaseEndpoints.order}/update-solution/${orderId}/${solutionId}`,
    reqObj
  );
};

export const APIGetSolution = async (
  orderId: string,
  solutionId: string
): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.order}/get-solution/${orderId}/${solutionId}`
  );
};

export const APIFetchAllOrderDetails = async (
  filterReq: GetAllOrdersReqEntityV1
): Promise<AxiosResponse> => {
  let query = "";

  query = query.concat(`pageSize=${filterReq.pageSize}`);

  query = query.concat(`&pageNumber=${filterReq.pageNumber + 1}`);

  if (
    filterReq.orderStatus &&
    !IsStringNullEmptyOrUndefined(filterReq.orderStatus)
  ) {
    query = query.concat(`&orderStatus=${filterReq.orderStatus}`);
  }

  if (
    filterReq.sortColumn &&
    !IsStringNullEmptyOrUndefined(filterReq.sortColumn)
  ) {
    query = query.concat(`&sortColumn=${filterReq.sortColumn}`);
  }

  if (
    filterReq.sortOrder &&
    !IsStringNullEmptyOrUndefined(filterReq.sortOrder)
  ) {
    query = query.concat(`&sortOrder=${filterReq.sortOrder}`);
  }

  if (
    filterReq.searchByOrderNumber &&
    !IsStringNullEmptyOrUndefined(filterReq.searchByOrderNumber)
  ) {
    query = query.concat(
      `&searchByOrderNumber=${filterReq.searchByOrderNumber}`
    );
  }

  if (
    filterReq.searchByProjectName &&
    !IsStringNullEmptyOrUndefined(filterReq.searchByProjectName)
  ) {
    query = query.concat(
      `&searchByProjectName=${filterReq.searchByProjectName}`
    );
  }

  if (
    filterReq.searchByClientName &&
    !IsStringNullEmptyOrUndefined(filterReq.searchByClientName)
  ) {
    query = query.concat(`&searchByClientName=${filterReq.searchByClientName}`);
  }

  // if (filterReq.searchByLocation && !IsStringNullEmptyOrUndefined(filterReq.searchByLocation)) {
  //     query = query.concat(`&searchByLocation=${filterReq.searchByLocation}`);
  // }

  if (
    filterReq.searchByState &&
    !IsStringNullEmptyOrUndefined(filterReq.searchByState)
  ) {
    query = query.concat(`&searchByState=${filterReq.searchByState}`);
  }

  if (
    filterReq.searchByCity &&
    !IsStringNullEmptyOrUndefined(filterReq.searchByCity)
  ) {
    query = query.concat(`&searchByCity=${filterReq.searchByCity}`);
  }

  if (
    filterReq.orderFromDate &&
    !IsStringNullEmptyOrUndefined(filterReq.orderFromDate)
  ) {
    query = query.concat(`&orderFromDate=${filterReq.orderFromDate}`);
  }

  if (
    filterReq.orderToDate &&
    !IsStringNullEmptyOrUndefined(filterReq.orderToDate)
  ) {
    query = query.concat(`&orderToDate=${filterReq.orderToDate}`);
  }

  if (
    filterReq.orderAmountFrom &&
    !IsStringNullEmptyOrUndefined(filterReq.orderAmountFrom)
  ) {
    query = query.concat(`&orderAmountFrom=${filterReq.orderAmountFrom}`);
  }

  if (
    filterReq.orderAmountTo &&
    !IsStringNullEmptyOrUndefined(filterReq.orderAmountTo)
  ) {
    query = query.concat(`&orderAmountTo=${filterReq.orderAmountTo}`);
  }

  query = query.concat(`&createdBy=${filterReq.createdBy}`);

  return await axios.get(
    `${APIURL}/${BaseEndpoints.order}/v1.0/get-all?${query}`
  );
};

export const APIDeleteOrder = async (id: string): Promise<AxiosResponse> => {
  return await axios.delete(`${APIURL}/${BaseEndpoints.order}/delete/${id}`);
};

export const APIDeleteSoloution = async (
  orderId: string,
  solutionId: string
): Promise<AxiosResponse> => {
  return await axios.delete(
    `${APIURL}/${BaseEndpoints.order}/delete-solution/${orderId}/${solutionId}`
  );
};

export const APIGenerateOrder = async (
  reqObj: GenerateOrderReqEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.order}/v1.0/generate-order`,
    reqObj
  );
};

export const APICreateframeColors = async (
  reqObj: FrameColorsReqEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.frameColorsSettings}/create`,
    reqObj
  );
};

export const APIFetchAllFrameColorsDetails = async (
  filterReq: PaginateReqEntity
): Promise<AxiosResponse> => {
  let query = "";

  query = query.concat(`pageSize=${filterReq.pageSize}`);

  query = query.concat(`&pageNumber=${filterReq.pageNumber + 1}`);

  query = query.concat(`&searchText=${filterReq.searchText}`);

  return await axios.get(
    `${APIURL}/${BaseEndpoints.frameColorsSettings}/get-all?${query}`
  );
};

export const APIDeleteFrameColor = async (
  id: string
): Promise<AxiosResponse> => {
  return await axios.delete(
    `${APIURL}/${BaseEndpoints.frameColorsSettings}/delete/${id}`
  );
};

export const APIUpdateFrameColor = async (
  reqObj: FrameColorsReqEntity,
  id: string
): Promise<AxiosResponse> => {
  return await axios.put(
    `${APIURL}/${BaseEndpoints.frameColorsSettings}/update/${id}`,
    reqObj
  );
};

export const APICreateDesgins = async (
  reqObj: DesginReqEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.desginSettings}/create`,
    reqObj
  );
};

export const APIFetchAllDesginsDetails = async (
  filterReq: GetAllDesignReqEntity
): Promise<AxiosResponse> => {
  let query = "";

  query = query.concat(`pageSize=${filterReq.pageSize}`);

  query = query.concat(`&pageNumber=${filterReq.pageNumber + 1}`);

  if (
    filterReq.sortColumn &&
    !IsStringNullEmptyOrUndefined(filterReq.sortColumn)
  ) {
    query = query.concat(`&sortColumn=${filterReq.sortColumn}`);
  }

  if (
    filterReq.sortOrder &&
    !IsStringNullEmptyOrUndefined(filterReq.sortOrder)
  ) {
    query = query.concat(`&sortOrder=${filterReq.sortOrder}`);
  }

  if (filterReq.fromDate && !IsStringNullEmptyOrUndefined(filterReq.fromDate)) {
    query = query.concat(`&fromDate=${filterReq.fromDate}`);
  }

  if (filterReq.toDate && !IsStringNullEmptyOrUndefined(filterReq.toDate)) {
    query = query.concat(`&toDate=${filterReq.toDate}`);
  }

  if (
    filterReq.searchDesignName &&
    !IsStringNullEmptyOrUndefined(filterReq.searchDesignName)
  ) {
    query = query.concat(`&searchDesignName=${filterReq.searchDesignName}`);
  }

  if (
    filterReq.glassOnePercent &&
    !IsStringNullEmptyOrUndefined(filterReq.glassOnePercent)
  ) {
    query = query.concat(`&glassOnePercent=${filterReq.glassOnePercent}`);
  }

  if (
    filterReq.glassTwoPercent &&
    !IsStringNullEmptyOrUndefined(filterReq.glassTwoPercent)
  ) {
    query = query.concat(`&glassTwoPercent=${filterReq.glassTwoPercent}`);
  }

  if (
    filterReq.glassPatternCostFrom &&
    !IsStringNullEmptyOrUndefined(filterReq.glassPatternCostFrom)
  ) {
    query = query.concat(
      `&glassPatternCostFrom=${filterReq.glassPatternCostFrom}`
    );
  }

  if (
    filterReq.glassPatternCostTo &&
    !IsStringNullEmptyOrUndefined(filterReq.glassPatternCostTo)
  ) {
    query = query.concat(`&glassPatternCostTo=${filterReq.glassPatternCostTo}`);
  }

  if (filterReq.status) {
    query = query.concat(`&status=${filterReq.status}`);
  }

  if (
    filterReq.glassCount &&
    !IsStringNullEmptyOrUndefined(filterReq.glassCount)
  ) {
    query = query.concat(`&glassCount=${filterReq.glassCount}`);
  }

  return await axios.get(
    `${APIURL}/${BaseEndpoints.desginSettings}/get-all?${query}`
  );
};

export const APIDeleteDesgins = async (id: string): Promise<AxiosResponse> => {
  return await axios.delete(
    `${APIURL}/${BaseEndpoints.desginSettings}/delete/${id}`
  );
};

export const APIGetDesginById = async (id: string): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.desginSettings}/get-design/${id}`
  );
};

export const APIUpdateDesgins = async (
  reqObj: FrameColorsReqEntity,
  id: string
): Promise<AxiosResponse> => {
  return await axios.put(
    `${APIURL}/${BaseEndpoints.desginSettings}/update/${id}`,
    reqObj
  );
};

export const APICreateGrid = async (reqObj: any): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.gridSettings}/create`,
    reqObj
  );
};

export const APIDesginDDL = async (data: {
  ids: string[] | [];
}): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.desginSettings}/get-ddl`,
    data
  );
};

export const APIFetchAllGridDetails = async (
  filterReq: PaginateReqEntity
): Promise<AxiosResponse> => {
  let query = "";

  query = query.concat(`pageSize=${filterReq.pageSize}`);

  query = query.concat(`&pageNumber=${filterReq.pageNumber + 1}`);

  query = query.concat(`&searchText=${filterReq.searchText}`);

  return await axios.get(
    `${APIURL}/${BaseEndpoints.gridSettings}/get-all?${query}`
  );
};

export const APIDeleteGrid = async (id: string): Promise<AxiosResponse> => {
  return await axios.delete(
    `${APIURL}/${BaseEndpoints.gridSettings}/delete/${id}`
  );
};

export const APIGridById = async (id: string): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.gridSettings}/get-grid/${id}`
  );
};

export const APIUpdateGrid = async (
  reqObj: FrameColorsReqEntity,
  id: string
): Promise<AxiosResponse> => {
  return await axios.put(
    `${APIURL}/${BaseEndpoints.gridSettings}/update/${id}`,
    reqObj
  );
};

export const APIUplodeFrameColorImage = async (
  data: FormData,
  id: string
): Promise<AxiosResponse> => {
  const instance = axios.create({
    headers: {
      "content-type": "multipart/form-data",
      "Custom-Multipart-Header": "true",
      Authorization: `Bearer ${getLocalStorageItem(
        StorageKeyEnum.JB_GLASS_AUTH_TOKEN
      )}`,
    },
  });
  return await instance.put(
    `${APIURL}/${BaseEndpoints.frameColorsSettings}/upload-media/${id}`,
    data
  );
};

export const APIUplodeDesginsImage = async (
  data: FormData,
  id: string
): Promise<AxiosResponse> => {
  const instance = axios.create({
    headers: {
      "content-type": "multipart/form-data",
      "Custom-Multipart-Header": "true",
      Authorization: `Bearer ${getLocalStorageItem(
        StorageKeyEnum.JB_GLASS_AUTH_TOKEN
      )}`,
    },
  });
  return await instance.put(
    `${APIURL}/${BaseEndpoints.desginSettings}/upload-media/${id}`,
    data
  );
};

export const APICreateGlassVariants = async (
  reqObj: GlassVariantsReqEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.glassVariantSettings}/create`,
    reqObj
  );
};

export const APIFetchAllGlassVariants = async (
  filterReq: GetAllWaltzVariantReqEntity
): Promise<AxiosResponse> => {
  let query = "";

  query = query.concat(`pageSize=${filterReq.pageSize}`);

  query = query.concat(`&pageNumber=${filterReq.pageNumber + 1}`);

  if (
    filterReq.sortColumn &&
    !IsStringNullEmptyOrUndefined(filterReq.sortColumn)
  ) {
    query = query.concat(`&sortColumn=${filterReq.sortColumn}`);
  }

  if (
    filterReq.sortOrder &&
    !IsStringNullEmptyOrUndefined(filterReq.sortOrder)
  ) {
    query = query.concat(`&sortOrder=${filterReq.sortOrder}`);
  }

  if (
    filterReq.createFromDate &&
    !IsStringNullEmptyOrUndefined(filterReq.createFromDate)
  ) {
    query = query.concat(`&createFromDate=${filterReq.createFromDate}`);
  }

  if (
    filterReq.createToDate &&
    !IsStringNullEmptyOrUndefined(filterReq.createToDate)
  ) {
    query = query.concat(`&createToDate=${filterReq.createToDate}`);
  }

  if (filterReq.name && !IsStringNullEmptyOrUndefined(filterReq.name)) {
    query = query.concat(`&name=${filterReq.name}`);
  }

  if (
    filterReq.printName &&
    !IsStringNullEmptyOrUndefined(filterReq.printName)
  ) {
    query = query.concat(`&printName=${filterReq.printName}`);
  }

  if (filterReq.status) {
    query = query.concat(`&status=${filterReq.status}`);
  }

  if (
    filterReq.fromAmount &&
    !IsStringNullEmptyOrUndefined(filterReq.fromAmount)
  ) {
    query = query.concat(`&fromAmount=${filterReq.fromAmount}`);
  }

  if (filterReq.toAmount && !IsStringNullEmptyOrUndefined(filterReq.toAmount)) {
    query = query.concat(`&toAmount=${filterReq.toAmount}`);
  }

  return await axios.get(
    `${APIURL}/${BaseEndpoints.glassVariantSettings}/get-all?${query}`
  );
};

export const APIDeleteGlassVariants = async (
  id: string
): Promise<AxiosResponse> => {
  return await axios.delete(
    `${APIURL}/${BaseEndpoints.glassVariantSettings}/delete/${id}`
  );
};

export const APIGlassVariantById = async (
  id: string
): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.glassVariantSettings}/get-variant/${id}`
  );
};

export const APIUpdateGlassVariants = async (
  reqObj: GlassVariantsReqEntity,
  id: string
): Promise<AxiosResponse> => {
  return await axios.put(
    `${APIURL}/${BaseEndpoints.glassVariantSettings}/update/${id}`,
    reqObj
  );
};

export const APIUplodeGlassVariantImage = async (
  data: FormData,
  id: string,
  fieldName: string
): Promise<AxiosResponse> => {
  const instance = axios.create({
    headers: {
      "content-type": "multipart/form-data",
      "Custom-Multipart-Header": "true",
      Authorization: `Bearer ${getLocalStorageItem(
        StorageKeyEnum.JB_GLASS_AUTH_TOKEN
      )}`,
      fieldName: fieldName,
    },
  });
  return await instance.put(
    `${APIURL}/${BaseEndpoints.glassVariantSettings}/upload-media/${id}`,
    data
  );
};

export const APICreateGlassFinishes = async (
  reqObj: GlassFinishesReqEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.glassfinishesSettings}/create`,
    reqObj
  );
};

export const APIGlassVariantsDDL = async (data: {
  ids: string[] | [];
}): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.glassVariantSettings}/get-ddl`,
    data
  );
};

export const APIUplodeGlassFinishesImage = async (
  data: FormData,
  id: string
): Promise<AxiosResponse> => {
  const instance = axios.create({
    headers: {
      "content-type": "multipart/form-data",
      "Custom-Multipart-Header": "true",
      Authorization: `Bearer ${getLocalStorageItem(
        StorageKeyEnum.JB_GLASS_AUTH_TOKEN
      )}`,
    },
  });
  return await instance.put(
    `${APIURL}/${BaseEndpoints.glassfinishesSettings}/upload-media/${id}`,
    data
  );
};

export const APIFetchAllGlassFinishes = async (
  filterReq: GetAllWaltzFinishReqEntity
): Promise<AxiosResponse> => {
  let query = "";

  query = query.concat(`pageSize=${filterReq.pageSize}`);

  query = query.concat(`&pageNumber=${filterReq.pageNumber + 1}`);

  if (
    filterReq.sortColumn &&
    !IsStringNullEmptyOrUndefined(filterReq.sortColumn)
  ) {
    query = query.concat(`&sortColumn=${filterReq.sortColumn}`);
  }

  if (
    filterReq.sortOrder &&
    !IsStringNullEmptyOrUndefined(filterReq.sortOrder)
  ) {
    query = query.concat(`&sortOrder=${filterReq.sortOrder}`);
  }

  if (filterReq.fromDate && !IsStringNullEmptyOrUndefined(filterReq.fromDate)) {
    query = query.concat(`&fromDate=${filterReq.fromDate}`);
  }

  if (filterReq.toDate && !IsStringNullEmptyOrUndefined(filterReq.toDate)) {
    query = query.concat(`&toDate=${filterReq.toDate}`);
  }

  if (
    filterReq.searchFinishName &&
    !IsStringNullEmptyOrUndefined(filterReq.searchFinishName)
  ) {
    query = query.concat(`&searchFinishName=${filterReq.searchFinishName}`);
  }

  if (filterReq.status) {
    query = query.concat(`&status=${filterReq.status}`);
  }

  return await axios.get(
    `${APIURL}/${BaseEndpoints.glassfinishesSettings}/get-all?${query}`
  );
};

export const APIDeleteGlassFinishes = async (
  id: string
): Promise<AxiosResponse> => {
  return await axios.delete(
    `${APIURL}/${BaseEndpoints.glassfinishesSettings}/delete/${id}`
  );
};

export const APIGlassFinishesById = async (
  id: string
): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.glassfinishesSettings}/get-finish/${id}`
  );
};

export const APIUpdateGlassFinishes = async (
  reqObj: GlassFinishesReqEntity,
  id: string
): Promise<AxiosResponse> => {
  return await axios.put(
    `${APIURL}/${BaseEndpoints.glassfinishesSettings}/update/${id}`,
    reqObj
  );
};

export const APIDownloadWaltzOrderOldPDF = async (orderId: string): Promise<AxiosResponse> => {
  return await axios.get(`${APIURL}/${BaseEndpoints.download}/create-waltz-order-pdf/${orderId}`);
};

export const APIDownloadWaltzOrderNewPDF = async (
  orderId: string
): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.waltzOder}/v1.0/waltz-order-new-pdf/${orderId}`
  );
};

export const APICreateHandles = async (
  reqObj: HandlesReqEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.handlesSettings}/create`,
    reqObj
  );
};

export const APIUplodeHandlesImage = async (
  data: FormData,
  id: string
): Promise<AxiosResponse> => {
  const instance = axios.create({
    headers: {
      "content-type": "multipart/form-data",
      "Custom-Multipart-Header": "true",
      Authorization: `Bearer ${getLocalStorageItem(
        StorageKeyEnum.JB_GLASS_AUTH_TOKEN
      )}`,
    },
  });
  return await instance.put(
    `${APIURL}/${BaseEndpoints.handlesSettings}/upload-media/${id}`,
    data
  );
};

export const APIFetchAllHandles = async (
  filterReq: PaginateReqEntity
): Promise<AxiosResponse> => {
  let query = "";

  query = query.concat(`pageSize=${filterReq.pageSize}`);

  query = query.concat(`&pageNumber=${filterReq.pageNumber + 1}`);

  if (
    filterReq.searchText &&
    !IsStringNullEmptyOrUndefined(filterReq.searchText)
  ) {
    query = query.concat(`&searchText=${filterReq.searchText}`);
  }

  if (filterReq.status) {
    query = query.concat(`&status=${filterReq.status}`);
  }

  return await axios.get(
    `${APIURL}/${BaseEndpoints.handlesSettings}/get-all?${query}`
  );
};

export const APIDeleteHandles = async (id: string): Promise<AxiosResponse> => {
  return await axios.delete(
    `${APIURL}/${BaseEndpoints.handlesSettings}/delete/${id}`
  );
};

export const APIUpdateHandles = async (
  reqObj: HandlesReqEntity,
  id: string
): Promise<AxiosResponse> => {
  return await axios.put(
    `${APIURL}/${BaseEndpoints.handlesSettings}/update/${id}`,
    reqObj
  );
};

export const APICreateLock = async (
  reqObj: LockReqEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.lockSettings}/create`,
    reqObj
  );
};

export const APIFetchAllLock = async (
  filterReq: PaginateReqEntity
): Promise<AxiosResponse> => {
  let query = "";

  query = query.concat(`pageSize=${filterReq.pageSize}`);

  query = query.concat(`&pageNumber=${filterReq.pageNumber + 1}`);

  if (
    filterReq.searchText &&
    !IsStringNullEmptyOrUndefined(filterReq.searchText)
  ) {
    query = query.concat(`&searchText=${filterReq.searchText}`);
  }

  if (filterReq.status) {
    query = query.concat(`&status=${filterReq.status}`);
  }

  return await axios.get(
    `${APIURL}/${BaseEndpoints.lockSettings}/get-all?${query}`
  );
};

export const APIDeleteLock = async (id: string): Promise<AxiosResponse> => {
  return await axios.delete(
    `${APIURL}/${BaseEndpoints.lockSettings}/delete/${id}`
  );
};

export const APIUpdateLock = async (
  reqObj: LockReqEntity,
  id: string
): Promise<AxiosResponse> => {
  return await axios.put(
    `${APIURL}/${BaseEndpoints.lockSettings}/update/${id}`,
    reqObj
  );
};

export const APIUplodeLockImage = async (
  data: FormData,
  id: string
): Promise<AxiosResponse> => {
  const instance = axios.create({
    headers: {
      "content-type": "multipart/form-data",
      "Custom-Multipart-Header": "true",
      Authorization: `Bearer ${getLocalStorageItem(
        StorageKeyEnum.JB_GLASS_AUTH_TOKEN
      )}`,
    },
  });
  return await instance.put(
    `${APIURL}/${BaseEndpoints.lockSettings}/upload-media/${id}`,
    data
  );
};

export const APISignInUser = async (
  reqObj: SignInReqEntity
): Promise<AxiosResponse> => {
  return await axios.post(`${APIURL}/user/sign-in`, reqObj);
};

export const APIGetGeneralSettings = async (): Promise<AxiosResponse> => {
  return await axios.get(`${APIURL}/${BaseEndpoints.generalSettings}/get`);
};

export const APIUpdateGeneralSettings = async (
  reqObj: any,
  id: string
): Promise<AxiosResponse> => {
  return await axios.put(
    `${APIURL}/${BaseEndpoints.generalSettings}/update/${id}`,
    reqObj
  );
};

export const APISaveLedgerDetails = async (
  reqObj: LedgerReqEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.order}/v1.0/save-ledger-details`,
    reqObj
  );
};

export const APIGetUserDDl = async (): Promise<AxiosResponse> => {
  return await axios.get(`${APIURL}/${BaseEndpoints.user}/get-ddl`);
};

export const APIGetGlassApplications = async (): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.glassOrder}/get-application`
  );
};

export const APIGetGlGlassSubCategoryDDl = async (): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.glGlassSubCategoriesSettings}/get-ddl`
  );
};

export const APICreateGlGlassCategories = async (
  reqObj: GlGlassCategoryReqEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.glGlassCategoriesSettings}/create`,
    reqObj
  );
};

export const APIFetchAllGlGlassCategories = async (
  filterReq: PaginateReqEntity
): Promise<AxiosResponse> => {
  let query = "";

  query = query.concat(`pageSize=${filterReq.pageSize}`);

  query = query.concat(`&pageNumber=${filterReq.pageNumber + 1}`);

  query = query.concat(`&searchText=${filterReq.searchText}`);

  return await axios.get(
    `${APIURL}/${BaseEndpoints.glGlassCategoriesSettings}/get-all?${query}`
  );
};

export const APIDeleteGlGlassCategories = async (
  id: string
): Promise<AxiosResponse> => {
  return await axios.delete(
    `${APIURL}/${BaseEndpoints.glGlassCategoriesSettings}/delete/${id}`
  );
};

export const APIUpdateGlGlassCategories = async (
  reqObj: GlGlassCategoryReqEntity,
  id: string
): Promise<AxiosResponse> => {
  return await axios.put(
    `${APIURL}/${BaseEndpoints.glGlassCategoriesSettings}/update/${id}`,
    reqObj
  );
};

export const APIGetGlGlassFinishDDl = async (): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.glGlassfinishesSettings}/get-ddl`
  );
};

export const APIUplodeGLGlassFinishesImage = async (
  data: FormData,
  id: string
): Promise<AxiosResponse> => {
  const instance = axios.create({
    headers: {
      "content-type": "multipart/form-data",
      "Custom-Multipart-Header": "true",
      Authorization: `Bearer ${getLocalStorageItem(
        StorageKeyEnum.JB_GLASS_AUTH_TOKEN
      )}`,
    },
  });
  return await instance.put(
    `${APIURL}/${BaseEndpoints.glGlassfinishesSettings}/upload-media/${id}`,
    data
  );
};

export const APICreateGlGlassSubCategory = async (
  reqPayload: GlGlassSubCategoryReqEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.glGlassSubCategoriesSettings}/create`,
    reqPayload
  );
};

export const APIFetchGlGlassSubCategorty = async (
  filterReq: PaginateReqEntity
): Promise<AxiosResponse> => {
  let query = "";

  query = query.concat(`pageSize=${filterReq.pageSize}`);

  query = query.concat(`&pageNumber=${filterReq.pageNumber + 1}`);

  query = query.concat(`&searchText=${filterReq.searchText}`);

  return await axios.get(
    `${APIURL}/${BaseEndpoints.glGlassSubCategoriesSettings}/get-all?${query}`
  );
};

export const APIDeleteGlGlassSubCategory = async (
  id: string
): Promise<AxiosResponse> => {
  return await axios.delete(
    `${APIURL}/${BaseEndpoints.glGlassSubCategoriesSettings}/delete/${id}`
  );
};

export const APIUpdateGlGlassSubCategory = async (
  id: string,
  reqPayload: GlGlassSubCategoryReqEntity
): Promise<AxiosResponse> => {
  return await axios.put(
    `${APIURL}/${BaseEndpoints.glGlassSubCategoriesSettings}/update/${id}`,
    reqPayload
  );
};

export const APIGetGlassOnlyCategoryDDl = async (reqBody: ConfigReqBodyEntity | null = null): Promise<AxiosResponse> => {

  return await !IsNullOrUndefined(reqBody) ? axios.post(
    `${APIURL}/${BaseEndpoints.panelOnly}/get-panel-config`, reqBody
  ) : axios.post(
    `${APIURL}/${BaseEndpoints.panelOnly}/get-panel-config`
  )
};

export const APIFetchGlassOnlyFinishConfig = async (
  reqPayload: GetGlassOrderConfigReq
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.glassOrder}/get-glass-order-config`,
    reqPayload
  );
};

export const APICreateGlassSolution = async (
  reqObj: GlassOrderSolutionReq
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.glassOrder}/create-glass-solution`,
    reqObj
  );
};

export const APIUpdateGlassSolution = async (
  reqObj: GlassOrderSolutionReq,
  glassOrderId: string,
  glassSolutionId: string
): Promise<AxiosResponse> => {
  return await axios.put(
    `${APIURL}/${BaseEndpoints.glassOrder}/update-glass-solution/${glassOrderId}/${glassSolutionId}`,
    reqObj
  );
};

export const APIGetGlassSolution = async (
  orderId: string,
  solutionId: string
): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.order}/get-glass-solution/${orderId}/${solutionId}`
  );
};

export const APIGetJBCode = async (
  glassCategoryId: string,
  glassSubCategoryId: string
): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.glassOnlyFinishSettings}/get-jb-code/${glassCategoryId}/${glassSubCategoryId}`
  );
};

export const APICreateOnlyGlassFinish = async (
  reqPayload: GlassOnlyFinishesEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.glassOnlyFinishSettings}/create`,
    reqPayload
  );
};

export const APIFetchAllGlassOnlyFinishes = async (
  filterReq: PaginateReqEntity
): Promise<AxiosResponse> => {
  let query = "";

  query = query.concat(`pageSize=${filterReq.pageSize}`);

  query = query.concat(`&pageNumber=${filterReq.pageNumber + 1}`);

  query = query.concat(`&searchText=${filterReq.searchText}`);

  return await axios.get(
    `${APIURL}/${BaseEndpoints.glassOnlyFinishSettings}/get-all?${query}`
  );
};

export const APIGetGlassFinishById = async (
  id: string
): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.glassOnlyFinishSettings}/get-glass-only-finish/${id}`
  );
};

export const APIUpdateOnlyGlassFinish = async (
  reqObj: GlassOnlyFinishesEntity,
  id: string
): Promise<AxiosResponse> => {
  return await axios.put(
    `${APIURL}/${BaseEndpoints.glassOnlyFinishSettings}/update/${id}`,
    reqObj
  );
};

export const APIDeleteOnlyGlassFinish = async (
  id: string
): Promise<AxiosResponse> => {
  return await axios.delete(
    `${APIURL}/${BaseEndpoints.glassOnlyFinishSettings}/delete/${id}`
  );
};

export const APIGetWaltzOrderForGlassOrder =
  async (): Promise<AxiosResponse> => {
    return await axios.get(
      `${APIURL}/${BaseEndpoints.order}/fetch-waltz-order-for-glass-order`
    );
  };

export const APIUplodeMediaGlassOnlyFinish = async (
  data: FormData,
  id: string,
  fieldName: string
): Promise<AxiosResponse> => {
  const instance = axios.create({
    headers: {
      "content-type": "multipart/form-data",
      "Custom-Multipart-Header": "true",
      Authorization: `Bearer ${getLocalStorageItem(
        StorageKeyEnum.JB_GLASS_AUTH_TOKEN
      )}`,
      fieldName: fieldName,
    },
  });
  return await instance.put(
    `${APIURL}/${BaseEndpoints.glassOnlyFinishSettings}/upload-media/${id}`,
    data
  );
};

export const APICreateGlassOrder = async (
  reqObj: CreateGlassOrderReqEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.glassOrder}/create-glass-order`,
    reqObj
  );
};

export const APIGetGlassOrderDetailsById = async (
  glassOrderId: string
): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.glassOrder}/fetch-glass-order-details/${glassOrderId}`
  );
};

export const APIGenerateGlassOrder = async (
  reqObj: GenerateGlassOrderReqEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.glassOrder}/generate-order`,
    reqObj
  );
};

export const APIGetGlassOrderSolution = async (
  orderId: string,
  solutionId: string
): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.glassOrder}/get-glass-solution/${orderId}/${solutionId}`
  );
};

export const APIFetchAllGlassOrders = async (
  filterReq: OrderPaginateEntity
): Promise<AxiosResponse> => {
  let query = "";

  query = query.concat(`pageSize=${filterReq.pageSize}`);

  query = query.concat(`&pageNumber=${filterReq.pageNumber + 1}`);

  query = query.concat(`&searchText=${filterReq.searchText}`);

  query = query.concat(`&orderStatus=${filterReq.orderStatus}`);

  query = query.concat(`&createdBy=${filterReq.createdBy}`);

  return await axios.get(
    `${APIURL}/${BaseEndpoints.glassOrder}/get-all?${query}`
  );
};

export const APIDeleteGlassOrder = async (
  id: string
): Promise<AxiosResponse> => {
  return await axios.delete(
    `${APIURL}/${BaseEndpoints.glassOrder}/delete/${id}`
  );
};

export const APIDeleteGlassOrderSoloution = async (
  glassOrderId: string,
  glassSolutionId: string
): Promise<AxiosResponse> => {
  return await axios.delete(
    `${APIURL}/${BaseEndpoints.glassOrder}/delete-glass-solution/${glassOrderId}/${glassSolutionId}`
  );
};

export const APIUpdateGlassOrderStatusCommercialWin = async (
  reqObj: UpdateOrderStatusCommercialWinReqEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.glassOrder}/update-order-status-commercial-win`,
    reqObj
  );
};

export const APIUpdateGlassOrderStatusHandover = async (
  reqObj: UpdateGlassOrderStatusHandoverReq
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.glassOrder}/update-order-status-handover`,
    reqObj
  );
};

export const APIDownloadGlassOrderPDF = async (
  orderId: string
): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.download}/create-glass-order-pdf/${orderId}`
  );
};

export const APIDownloadCustomerFeedbackFormPDF = async (
  orderId: string,
  keyName: string
): Promise<AxiosResponse> => {
  const query = `keyName=${keyName}`;
  return await axios.get(
    `${APIURL}/${BaseEndpoints.download}/customer-feedback-form-pdf/${orderId}?${query}`
  );
};

export const APIDownloadMeasurementSheetPDF = async (
  orderId: string,
  keyName: string
): Promise<AxiosResponse> => {
  const query = `keyName=${keyName}`;
  return await axios.get(
    `${APIURL}/${BaseEndpoints.download}/measurement-sheet-pdf/${orderId}?${query}`
  );
};

export const APIDownloadGoodsDeliveryNotePDF = async (
  orderId: string,
  keyName: string
): Promise<AxiosResponse> => {
  const query = `keyName=${keyName}`;
  return await axios.get(
    `${APIURL}/${BaseEndpoints.download}/goods-delivery-note-pdf/${orderId}?${query}`
  );
};
export const APICreateGoodsDeliveryNote = async (
  reqObj: GoodsDeleveryEntity & {
    orderId: string;
    orderNumber: string;
    dateSent: string;
    formatedDate: string;
  }
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.download}/create/goods-delivery-note`,
    reqObj
  );
};
export const APIDownloadWorkCompletionNotePDF = async (
  orderId: string,
  keyName: string
): Promise<AxiosResponse> => {
  const query = `keyName=${keyName}`;
  return await axios.get(
    `${APIURL}/${BaseEndpoints.download}/work-completion-note-pdf/${orderId}?${query}`
  );
};

export const APISaveLedgerDetailsForGlassOrder = async (
  reqObj: LedgerReqEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.glassOrder}/save-ledger-details`,
    reqObj
  );
};

export const APIGetWaltzOrderNumber = async (): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.order}/fetch-waltz-order-number-DDL`
  );
};

export const APIDownloadExcelReportForAnalytics = async (
  reqObj: DownloadReqBodyEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.analytics}/v1.0/download-excel-report`,
    reqObj
  );
};

export const APIGetOrderDetailsForExcel = async (
  orderId: string
): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.order}/fetch-order-details-for-excel/${orderId}`
  );
};

export const APIDownloadSalesCollectionReportForAnalytics = async (
  reqObj: DownloadReqBodyEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.analytics}/download-sales-collection-report`,
    reqObj
  );
};

export const APIProcoreSubmitAuthorizationCode = async (reqObj: {
  code: string;
}): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.procore}/submit-authorization-code`,
    reqObj
  );
};

export const APIDownloadIncentiveReport = async (
  reqObj: DownloadReqBodyEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.analytics}/download-incentive-report`,
    reqObj
  );
};

export const APIGetGlassOrderDetailsForExcel = async (
  orderId: string
): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.glassOrder}/fetch-order-calculation-details-for-excel/${orderId}`
  );
};

export const APIDownloadProPlusReportForAnalytics = async (
  reqObj: DownloadReqBodyEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.analytics}/download-pro-pluse-report`,
    reqObj
  );
};

export const APIGetWaltzOrderCalculationDetailsForExcel = async (
  orderId: string
): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.order}/fetch-order-calculation-details-for-excel/${orderId}`
  );
};

export const APIRefreshOrderWithProcore = async (
  orderId: string
): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.order}/v1.0/refresh-procore-details/${orderId}`
  );
};

export const APIUpdateProcoreProjectLinks = async (
  orderId: string
): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.order}/v1.0/refresh-procore-project-links/${orderId}`
  );
};

export const APIGetModuleName = async (): Promise<AxiosResponse> => {
  return await axios.get(`${APIURL}/${BaseEndpoints.modules}/get-module-name`);
};

export const APIGetAllModules = async (): Promise<AxiosResponse> => {
  return await axios.get(`${APIURL}/${BaseEndpoints.modules}/get-all`);
};

export const ApiCreateRole = async (
  reqObj: RolesReqEntity
): Promise<AxiosResponse> => {
  return await axios.post(`${APIURL}/${BaseEndpoints.role}/create`, reqObj);
};

export const APIUpdateRole = async (
  reqObj: RolesReqEntity,
  id: string
): Promise<AxiosResponse> => {
  return await axios.put(
    `${APIURL}/${BaseEndpoints.role}/update/${id}`,
    reqObj
  );
};

export const APIGetAllRoles = async (): Promise<AxiosResponse> => {
  return await axios.get(`${APIURL}/${BaseEndpoints.role}/get-all`);
};

export const APIGetRoleById = async (id: string): Promise<AxiosResponse> => {
  return await axios.get(`${APIURL}/${BaseEndpoints.role}/get-role/${id}`);
};

export const APIGetLedgerDetailsByOrderId = async (
  orderId: string
): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.order}/view-ledger-details/${orderId}`
  );
};

export const APICalculateSolutionFeatures = async (
  body: CreateUpdateSolutionReqPayloadEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.order}/v1.0/calculate-solution-features`,
    body
  );
};

export const APIgetCSAnalytics = async (): Promise<AxiosResponse> => {
  return await axios.get(`${APIURL}/${BaseEndpoints.analytics}/refresh-CS`);
};

export const APIgetProcoreAnalytics = async (): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.analytics}/refresh-procore`
  );
};

export const APIFetchOrdersCsvDetails = async (): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.order}/v1.0/dump-orders-csv`
  );
};

export const APIFetchModelsCsvDetails = async (): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.modelSetting}/get-model-csv`
  );
};

export const APIFetchWaltzVariantsCsvDetails =
  async (): Promise<AxiosResponse> => {
    return await axios.get(
      `${APIURL}/${BaseEndpoints.glassVariantSettings}/get-waltz-variant-csv`
    );
  };

export const APIFetchWaltzFinishesCsvDetails =
  async (): Promise<AxiosResponse> => {
    return await axios.get(
      `${APIURL}/${BaseEndpoints.glassFinishSettings}/get-waltz-finishes-csv`
    );
  };

export const APIFetchUsersCsvDetails = async (): Promise<AxiosResponse> => {
  return await axios.get(`${APIURL}/${BaseEndpoints.user}/get-users-csv`);
};

export const APIFetchLocksCsvDetails = async (): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.lockSettings}/get-locks-csv`
  );
};

export const APIFetchHandlesCsvDetails = async (): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.handlesSettings}/get-hanldes-csv`
  );
};

export const APIFetchWaltzCategoriesCsvDetails =
  async (): Promise<AxiosResponse> => {
    return await axios.get(
      `${APIURL}/${BaseEndpoints.glassCategoriesSettings}/get-waltz-category-csv`
    );
  };

export const APIFetchWaltzCategoriesCsvInDetails =
  async (): Promise<AxiosResponse> => {
    return await axios.get(
      `${APIURL}/${BaseEndpoints.glassCategoriesSettings}/get-waltz-category-in-details-csv`
    );
  };

export const ApiChangePassword = async (
  reqObj: ChangePasswordReqEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.user}/change-password`,
    reqObj
  );
};

export const ApiFirstTimeChangePassword = async (
  reqObj: FirstTimeChangePasswordReqEntity,
  signToken: string
): Promise<AxiosResponse> => {
  const instance = axios.create({
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${signToken}`,
    },
  });
  return await instance.post(
    `${APIURL}/${BaseEndpoints.user}/set-password-first-time`,
    reqObj
  );
};

export const APIUpdateOrderBasicDetails = async (
  reqObj: UpdateOrderReqEntity,
  id: string
): Promise<AxiosResponse> => {
  return await axios.put(
    `${APIURL}/${BaseEndpoints.order}/update-order-basic-details/${id}`,
    reqObj
  );
};

export const APIFetchImpactedValues = async (
  reqPayload: ReleaseNewPricingVersionReqEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.priceVersion}/fetch-impacted-values`,
    reqPayload
  );
};

export const APIReleaseNewVersion = async (
  reqPayload: ReleaseNewPricingVersionReqEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.priceVersion}/release-new`,
    reqPayload
  );
};

export const APIExportPriceVersion = async (
  id: string
): Promise<AxiosResponse> => {
  return await axios.get(`${APIURL}/export-price-version/${id}`);
};

export const APIFetchPricingVersion = async (): Promise<AxiosResponse> => {
  return await axios.get(`${APIURL}/${BaseEndpoints.priceVersion}/get-all`);
};

export const APIDeleteDraft = async (
  draftVersionId: string
): Promise<AxiosResponse> => {
  return await axios.delete(
    `${APIURL}/${BaseEndpoints.priceVersion}/delete-draft/${draftVersionId}`
  );
};

export const APIFetchChangeLogs = async (
  id: string
): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.priceVersion}/get-change-logs/${id}`
  );
};

export const APISaveAsDraft = async (
  reqPayload: ReleaseNewPricingVersionReqEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.priceVersion}/save-as-draft`,
    reqPayload
  );
};

export const APIGetStateDDL = async (
  stateId: number = 0
): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.states}/get-ddl/${stateId}`
  );
};

export const APIGetCityDDL = async (
  stateId: number,
  cityId: number = 0
): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.cities}/get-ddl/${stateId}/${cityId}`
  );
};

export const APIFetchCitiesCsvDetails = async (): Promise<AxiosResponse> => {
  return await axios.get(`${APIURL}/${BaseEndpoints.cities}/get-cities-csv`);
};

export const APIFetchStatesCsvDetails = async (): Promise<AxiosResponse> => {
  return await axios.get(`${APIURL}/${BaseEndpoints.states}/get-state-csv`);
};

export const APICreateStates = async (
  reqPayload: StatesReqEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.states}/create`,
    reqPayload
  );
};

export const APIFetchAllStates = async (
  filterReq: GetAllStatesResEntity
): Promise<AxiosResponse> => {
  let query = "";

  query = query.concat(`pageSize=${filterReq.pageSize}`);

  query = query.concat(`&pageNumber=${filterReq.pageNumber + 1}`);

  if (
    filterReq.searchText &&
    !IsStringNullEmptyOrUndefined(filterReq.searchText)
  ) {
    query = query.concat(`&searchText=${filterReq.searchText}`);
  }

  if (
    filterReq.sortColumn &&
    !IsStringNullEmptyOrUndefined(filterReq.sortColumn)
  ) {
    query = query.concat(`&sortColumn=${filterReq.sortColumn}`);
  }

  if (
    filterReq.sortOrder &&
    !IsStringNullEmptyOrUndefined(filterReq.sortOrder)
  ) {
    query = query.concat(`&sortOrder=${filterReq.sortOrder}`);
  }

  return await axios.get(`${APIURL}/${BaseEndpoints.states}/get-all?${query}`);
};

export const APIUpdateStates = async (
  reqObj: StatesReqEntity,
  id: string
): Promise<AxiosResponse> => {
  return await axios.put(
    `${APIURL}/${BaseEndpoints.states}/update/${id}`,
    reqObj
  );
};

export const APIDeleteStates = async (id: string): Promise<AxiosResponse> => {
  return await axios.delete(`${APIURL}/${BaseEndpoints.states}/delete/${id}`);
};

export const APIFetchPricingVersionOrderWiseCount =
  async (): Promise<AxiosResponse> => {
    return await axios.get(
      `${APIURL}/${BaseEndpoints.priceVersion}/fetch-pricing-versions-order-wise-counts`
    );
  };

export const APIGetSamplePriceVersion = async (): Promise<AxiosResponse> => {
  return await axios.get(`${APIURL}/sample-pricing-version-file`);
};

export const APICreateCities = async (
  reqPayload: CityReqEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.cities}/create`,
    reqPayload
  );
};

export const APIFetchAllCities = async (
  filterReq: GetAllCitiesResEntity
): Promise<AxiosResponse> => {
  let query = "";

  query = query.concat(`pageSize=${filterReq.pageSize}`);

  query = query.concat(`&pageNumber=${filterReq.pageNumber + 1}`);

  if (
    filterReq.searchText &&
    !IsStringNullEmptyOrUndefined(filterReq.searchText)
  ) {
    query = query.concat(`&searchText=${filterReq.searchText}`);
  }

  if (
    filterReq.sortColumn &&
    !IsStringNullEmptyOrUndefined(filterReq.sortColumn)
  ) {
    query = query.concat(`&sortColumn=${filterReq.sortColumn}`);
  }

  if (
    filterReq.sortOrder &&
    !IsStringNullEmptyOrUndefined(filterReq.sortOrder)
  ) {
    query = query.concat(`&sortOrder=${filterReq.sortOrder}`);
  }

  return await axios.get(`${APIURL}/${BaseEndpoints.cities}/get-all?${query}`);
};

export const APIUpdateCities = async (
  reqObj: CityReqEntity,
  id: string
): Promise<AxiosResponse> => {
  return await axios.put(
    `${APIURL}/${BaseEndpoints.cities}/update/${id}`,
    reqObj
  );
};

export const APIDeleteCity = async (id: string): Promise<AxiosResponse> => {
  return await axios.delete(`${APIURL}/${BaseEndpoints.cities}/delete/${id}`);
};

export const APICreateOffice = async (
  reqObj: CreateUpdateOfficeReqEntity
): Promise<AxiosResponse> => {
  return await axios.post(`${APIURL}/${BaseEndpoints.office}/create`, reqObj);
};

export const APIGetOfficeById = async (id: string): Promise<AxiosResponse> => {
  return await axios.get(`${APIURL}/${BaseEndpoints.office}/get-office/${id}`);
};

export const APIGetOfficeDDL = async (): Promise<AxiosResponse> => {
  return await axios.get(`${APIURL}/${BaseEndpoints.office}/get-ddl`);
};

export const APIUpdateOffice = async (
  reqObj: CreateUpdateOfficeReqEntity,
  id: string
): Promise<AxiosResponse> => {
  return await axios.put(
    `${APIURL}/${BaseEndpoints.office}/update/${id}`,
    reqObj
  );
};

export const APIFetchOffices = async (
  filterReq: PaginateReqEntity
): Promise<AxiosResponse> => {
  let query = "";

  query = query.concat(`pageSize=${filterReq.pageSize}`);

  query = query.concat(`&pageNumber=${filterReq.pageNumber + 1}`);

  // query = query.concat(`&searchText=${filterReq.searchText}`);

  return await axios.get(`${APIURL}/${BaseEndpoints.office}/get-all?${query}`);
};

export const APIDeleteOffice = async (id: string): Promise<AxiosResponse> => {
  return await axios.delete(`${APIURL}/${BaseEndpoints.office}/delete/${id}`);
};

export const APIGetOfficedistribution = async (
  id: string
): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.office}/distribution/${id}`
  );
};

export const APIUplodeImportOrderMapping = async (
  data: FormData
): Promise<AxiosResponse> => {
  const instance = axios.create({
    headers: {
      "content-type": "multipart/form-data",
      "Custom-Multipart-Header": "true",
      Authorization: `Bearer ${getLocalStorageItem(
        StorageKeyEnum.JB_GLASS_AUTH_TOKEN
      )}`,
    },
  });
  return await instance.post(
    `${APIURL}/${BaseEndpoints.order}/v1.0/import-order-mapping`,
    data
  );
};

export const APIUplodeImportModelMapping = async (
  data: FormData
): Promise<AxiosResponse> => {
  const instance = axios.create({
    headers: {
      "content-type": "multipart/form-data",
      "Custom-Multipart-Header": "true",
      Authorization: `Bearer ${getLocalStorageItem(
        StorageKeyEnum.JB_GLASS_AUTH_TOKEN
      )}`,
    },
  });
  return await instance.post(
    `${APIURL}/${BaseEndpoints.modelSetting}/import-handle-lock-mapping`,
    data
  );
};

export const APIGetUnMappedUser = async (): Promise<AxiosResponse> => {
  return await axios.get(`${APIURL}/${BaseEndpoints.office}/get-unmapped-user`);
};

export const APISaveUserMapping = async (
  data: SaveUserMappingReqBodyEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.office}/save-user-mapping`,
    data
  );
};

export const APISaveRegionsMapping = async (
  data: any
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.office}/save-regions-mapping`,
    data
  );
};

export const APIGetLostReason = async (): Promise<AxiosResponse> => {
  return await axios.get(`${APIURL}/${BaseEndpoints.order}/v1.0/lost-reason`);
};

export const APIUpdateLostReason = async (obj: {
  orderId: string;
  reasonId: string;
}): Promise<AxiosResponse> => {
  return axios.post(
    `${APIURL}/${BaseEndpoints.order}/v1.0/order-lost-reason`,
    obj
  );
};

export const APIFetchUnMappedCityByStateId = async (
  stateId: number
): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.cities}/get-unmapped-city/${stateId}`
  );
};

export const APIGetReportFields = async (): Promise<AxiosResponse> => {
  return axios.get(`${APIURL}/${BaseEndpoints.analytics}/get-report-fields`);
};

export const APICreateReportFieldsMapping = async (
  obj: CreateReportFieldsMappingReqEntity
): Promise<AxiosResponse> => {
  return axios.post(
    `${APIURL}/${BaseEndpoints.analytics}/create-report-fields-mapping`,
    obj
  );
};

export const APIGetReportFieldsMapping = async (id: string) => {
  return axios.get(
    `${APIURL}/${BaseEndpoints.analytics}/get-report-fields-mapping/${id}`
  );
};

export const APIUpdateReportFieldsMapping = async (
  obj: CreateReportFieldsMappingReqEntity
): Promise<AxiosResponse> => {
  return axios.post(
    `${APIURL}/${BaseEndpoints.analytics}/update-report-fields-mapping`,
    obj
  );
};

export const APIExportOffice = async (): Promise<AxiosResponse> => {
  return axios.get(`${APIURL}/${BaseEndpoints.office}/export-office`);
};

export const APIIdentifySecondaryFector = async (
  reqObj: any
): Promise<AxiosResponse> => {
  return axios.post(
    `${APIURL}/${BaseEndpoints.order}/identify-secondary-factor`,
    reqObj
  );
};

export const APISearchArchitectFirmName = async (): Promise<AxiosResponse> => {
  return axios.post(
    `${APIURL}/${BaseEndpoints.order}/get-architect-firm-name`
  );
};

export const APIFetchOfficeAndUsers = async (): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.dashboard}/get-offices-users-ddl`
  );
};

export const APIFetchWonAndTotalProjects = async (
  body: DashboardFiltersEntity
): Promise<AxiosResponse> => {
  // let reqQueryParams: string = "";
  // if (
  //   queryParams.officeId &&
  //   !IsStringNullEmptyOrUndefined(queryParams.officeId)
  // ) {
  //   reqQueryParams = reqQueryParams.concat(`&officeId=${queryParams.officeId}`);
  // }
  // if (queryParams.userId && !IsStringNullEmptyOrUndefined(queryParams.userId)) {
  //   reqQueryParams = reqQueryParams.concat(`&userId=${queryParams.userId}`);
  // }
  // // if (
  // //   queryParams.startDate &&
  // //   !IsStringNullEmptyOrUndefined(queryParams.startDate)
  // // ) {
  // //   reqQueryParams = reqQueryParams.concat(
  // //     `&startDate=${queryParams.startDate}`
  // //   );
  // // }
  // // if (
  // //   queryParams.endDate &&
  // //   !IsStringNullEmptyOrUndefined(queryParams.endDate)
  // // ) {
  // //   reqQueryParams = reqQueryParams.concat(`&endDate=${queryParams.endDate}`);
  // // }
  // if( queryParams.dateRange && !IsNullOrEmptyArray(queryParams.dateRange))
  // {

  //     reqQueryParams = reqQueryParams.concat(
  //       `&dateRange=${queryParams.dateRange}`
  //     );

  // }
  // if (
  //   queryParams.financialYear &&
  //   !IsStringNullEmptyOrUndefined(queryParams.financialYear)
  // ) {
  //   reqQueryParams = reqQueryParams.concat(
  //     `&financialYear=${queryParams.financialYear}`
  //   );
  // }

  return await axios.post(
    `${APIURL}/${BaseEndpoints.dashboard}/get-revenue-chart-data`,
    body
  );
};

export const APIFetchProductByAmount = async (
  body: DashboardFiltersEntity
): Promise<AxiosResponse> => {
  // let reqQueryParams: string = "";
  // if (
  //   queryParams.officeId &&
  //   !IsStringNullEmptyOrUndefined(queryParams.officeId)
  // ) {
  //   reqQueryParams = reqQueryParams.concat(`&officeId=${queryParams.officeId}`);
  // }
  // if (queryParams.userId && !IsStringNullEmptyOrUndefined(queryParams.userId)) {
  //   reqQueryParams = reqQueryParams.concat(`&userId=${queryParams.userId}`);
  // }
  // if( queryParams.dateRange && !IsNullOrEmptyArray(queryParams.dateRange))
  //   {

  //       reqQueryParams = reqQueryParams.concat(
  //         `&dateRange=${queryParams.dateRange}`
  //       );

  //   }
  // if (
  //   queryParams.financialYear &&
  //   !IsStringNullEmptyOrUndefined(queryParams.financialYear)
  // ) {
  //   reqQueryParams = reqQueryParams.concat(
  //     `&financialYear=${queryParams.financialYear}`
  //   );
  // }
  // if (
  //   queryParams.orderStatus &&
  //   !IsStringNullEmptyOrUndefined(queryParams.orderStatus)
  // ) {
  //   reqQueryParams = reqQueryParams.concat(
  //     `&orderStatus=${queryParams.orderStatus}`
  //   );
  // }
  // console.log("reqQueryParams", reqQueryParams);

  return await axios.post(
    `${APIURL}/${BaseEndpoints.dashboard}/get-product-chart-data-total-amount`,
    body
  );
};

export const APIFetchProductBySquarFeet = async (
  body: DashboardFiltersEntity
): Promise<AxiosResponse> => {
  // let reqQueryParams: string = "";
  // if (
  //   queryParams.officeId &&
  //   !IsStringNullEmptyOrUndefined(queryParams.officeId)
  // ) {
  //   reqQueryParams = reqQueryParams.concat(`&officeId=${queryParams.officeId}`);
  // }
  // if (queryParams.userId && !IsStringNullEmptyOrUndefined(queryParams.userId)) {
  //   reqQueryParams = reqQueryParams.concat(`&userId=${queryParams.userId}`);
  // }
  // if( queryParams.dateRange && !IsNullOrEmptyArray(queryParams.dateRange))
  //   {

  //       reqQueryParams = reqQueryParams.concat(
  //         `&dateRange=${queryParams.dateRange}`
  //       );

  //   }
  // if (
  //   queryParams.financialYear &&
  //   !IsStringNullEmptyOrUndefined(queryParams.financialYear)
  // ) {
  //   reqQueryParams = reqQueryParams.concat(
  //     `&financialYear=${queryParams.financialYear}`
  //   );
  // }
  // if (
  //   queryParams.orderStatus &&
  //   !IsStringNullEmptyOrUndefined(queryParams.orderStatus)
  // ) {
  //   reqQueryParams = reqQueryParams.concat(
  //     `&orderStatus=${queryParams.orderStatus}`
  //   );
  // }
  // console.log("reqQueryParams", reqQueryParams);

  return await axios.post(
    `${APIURL}/${BaseEndpoints.dashboard}/get-product-chart-data-total-sqaure-feet`,
    body
  );
};

export const APIUpdateOrderStatusPreclosure = async (
  reqObj: UpdateOrderStatusPreclosureReq
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.order}/v1.0/update-order-status-preclosure`,
    reqObj
  );
};

export const APISendCustomerBillingFormLink = async (reqObj: {
  orderId: string;
  resend?: boolean;
}): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.order}/v1.0/send-customer-billing-form-link`,
    reqObj
  );
};

export const APIDocumentVerify = async (
  reqObj: any
): Promise<AxiosResponse> => {
  return await axios.post(`${APIURL}/document-verify`, reqObj);
};

export const APIVerifyCustomerBillingFormLink = async (reqObj: {
  orderId: string;
}): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.order}/v1.0/verify-customer-billing-form-link`,
    reqObj
  );
};

export const APISaveCustomerBillingInformation = async (
  reqObj: CustomerBillingInformationReqBodyEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.order}/v1.0/save-customer-billing-form`,
    reqObj
  );
};

export const APIVerifyOTP = async (reqObj: {
  emailAddress: string;
  otp: number;
}): Promise<AxiosResponse> => {
  return await axios.post(`${APIURL}/user/verify-otp`, reqObj);
};
export const APICreateArchitectInProcore = async (
  reqObj: any
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.order}/v1.0/create-architect-in-procore`,
    reqObj
  );
};

export const APISaveLedgerDetailsAsDraft = async (
  reqObj: any
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.order}/v1.0/save-ledger-details-as-draft`,
    reqObj
  );
};

export const APIUpdateOrderStatusCommercialHoldToPreclosure = async (
  reqObj: PreClosureOrderStatusCommercialWinReqEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.order}/v1.0/update-order-status-commercial-hold-to-preclosure`,
    reqObj
  );
};

export const APIUplodePurchaseOrderPdf = async (
  data: FormData,
  id: string
): Promise<AxiosResponse> => {
  const instance = axios.create({
    headers: {
      "content-type": "multipart/form-data",
      "Custom-Multipart-Header": "true",
      Authorization: `Bearer ${getLocalStorageItem(
        StorageKeyEnum.JB_GLASS_AUTH_TOKEN
      )}`,
    },
  });
  return await instance.put(
    `${APIURL}/${BaseEndpoints.order}/v1.0/upload-media/${id}`,
    data
  );
};

export const APIGetLedgerForGDN = async (
  orderId: string
): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.download}/get-site-person-detail?orderId=${orderId}`
  );
};

export const APIFetchCategoyStructure = async (): Promise<AxiosResponse> => {
  return await axios.get(`${APIURL}/${BaseEndpoints.glassCategoriesSettings}/fetch-category-structure`);
}

export const APICreatePanelOnly = async (reqObj: PanelOnlyReqEntity): Promise<AxiosResponse> => {
  return await axios.post(`${APIURL}/${BaseEndpoints.panelOnly}/create`, reqObj);
}

export const APIFetchCategoryTreeStructureForModel = async (id: string = ''): Promise<AxiosResponse> => {
  return await axios.get(`${APIURL}/${BaseEndpoints.modelSetting}/get-category-structure?id=${id}`);
}

export const APIGetPanelOnly = async (): Promise<AxiosResponse> => {
  return await axios.get(`${APIURL}/${BaseEndpoints.panelOnly}/get`);
};

export const APICalculateGlassSolutionFeatures = async (reqObj: CreateUpdateGlassSolutionReqPayloadEntity): Promise<AxiosResponse> => {
  return await axios.post(`${APIURL}/${BaseEndpoints.order}/v1.0/calculate-glass-order-features`, reqObj)
}

export const APIChangesOrderPerCentages = async (
  data: any
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.orderPercentage}/change-order-percentages`,
    data
  );
};

export const APIGetOrderNumberWithPercentage =
  async (): Promise<AxiosResponse> => {
    return await axios.get(
      `${APIURL}/${BaseEndpoints.orderPercentage}/fetch-order-number-DDL-with-percentages`
    );
  };

export const APIVerifyArchitectEmail = async (
  reqObj: any
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.order}/v1.0/verify-architect-email`,
    reqObj
  );
};

export const APIFetchAllArchitectDetails = async (
  filterReq: GetAllArchitectDetailsReqEntity
): Promise<AxiosResponse> => {
  let query = "";

  query = query.concat(`pageSize=${filterReq.pageSize}`);

  query = query.concat(`&pageNumber=${filterReq.pageNumber + 1}`);

  if (
    filterReq.sortColumn &&
    !IsStringNullEmptyOrUndefined(filterReq.sortColumn)
  ) {
    query = query.concat(`&sortColumn=${filterReq.sortColumn}`);
  }

  if (
    filterReq.sortOrder &&
    !IsStringNullEmptyOrUndefined(filterReq.sortOrder)
  ) {
    query = query.concat(`&sortOrder=${filterReq.sortOrder}`);
  }

  if (filterReq.fromDate && !IsStringNullEmptyOrUndefined(filterReq.fromDate)) {
    query = query.concat(`&fromDate=${filterReq.fromDate}`);
  }

  if (filterReq.toDate && !IsStringNullEmptyOrUndefined(filterReq.toDate)) {
    query = query.concat(`&toDate=${filterReq.toDate}`);
  }

  if (
    filterReq.orderNumber &&
    !IsStringNullEmptyOrUndefined(filterReq.orderNumber)
  ) {
    query = query.concat(`&orderNumber=${filterReq.orderNumber}`);
  }

  if (
    filterReq.companyName &&
    !IsStringNullEmptyOrUndefined(filterReq.companyName)
  ) {
    query = query.concat(`&companyName=${filterReq.companyName}`);
  }

  if (
    filterReq.salesPersonName &&
    !IsStringNullEmptyOrUndefined(filterReq.salesPersonName)
  ) {
    query = query.concat(`&salesPersonName=${filterReq.salesPersonName}`);
  }

  if (filterReq.status) {
    query = query.concat(`&status=${filterReq.status}`);
  }

  return await axios.get(
    `${APIURL}/${BaseEndpoints.architectDetails}/get-all?${query}`
  );
};

export const APIGetArchitectDetailById = async (id: string): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.architectDetails}/get-architect-details/${id}`
  );
};

export const APIExportArchitectDetails = async (): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.architectDetails}/export-architect-details`
  );
};

export const APIExportArchitectFirmAndArchitectDetails = async (): Promise<AxiosResponse> => {
  return await axios.get(
    `${APIURL}/${BaseEndpoints.architectDetails}/export-architect-firm-and-architect-details`
  );
};

export const APIApproveArchitectDetails = async (
  reqObj: ApproveArchitectDetailsReqBodyEntity
): Promise<AxiosResponse> => {
  return await axios.post(
    `${APIURL}/${BaseEndpoints.architectDetails}/approve-architect-details`,
    reqObj
  );
};

export const APIFetchAllOrderPercentageUpdate = async (
  filterReq: PaginateReqEntity
): Promise<AxiosResponse> => {
  let query = "";

  query = query.concat(`pageSize=${filterReq.pageSize}`);

  query = query.concat(`&pageNumber=${filterReq.pageNumber + 1}`);

  query = query.concat(`&searchText=${filterReq.searchText}`);

  return await axios.get(
    `${APIURL}/${BaseEndpoints.orderPercentage}/get-all?${query}`
  );
};


export const APIGetLedgerDetailExcel = async (orderId: string): Promise<AxiosResponse> => {
  return await axios.get(`${APIURL}/${BaseEndpoints.order}/v1.0/export-ledger-csv/${orderId}`);
}